import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Intro from "../../components/servicos/intro"
import IntroMobile from "../../components/servicos/introMobile"
import Banner from "../../components/servicos/banner"
import Info from "../../components/servicos/infoPerio"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import FormServicos from "../../components/servicos/formServicos"
import FormServicosMobile from "../../components/servicos/formServicosMobile"
import Img from '../../images/servicos/imgs/periodontologia/periodontologia.jpg'

const PeriodontologiaPage = ({ data }) => (
  <Layout>
    <SEO
      title="Periodontologia"
      description={data.periodontologiaJson.intro.text}
    />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <IntroMobile data={data.periodontologiaJson.intro} img={Img} />
        <Banner data={data.periodontologiaJson.banner} alt={data.periodontologiaJson.alt}/>
        <Info data={data.periodontologiaJson.content} />
        <FormServicosMobile nome="Periodontologia" />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Intro data={data.periodontologiaJson.intro} img={Img}/>
        <Banner data={data.periodontologiaJson.banner} alt={data.periodontologiaJson.alt}/>
        <Info data={data.periodontologiaJson.content} />
        <FormServicos nome="Periodontologia" />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default PeriodontologiaPage

export const Json = graphql`
  query periodontologia {
    periodontologiaJson {
      intro {
        title
        text
      }
      banner {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alt
      content {
        halito {
          title
          text
          list
        }
        gengivite {
          title
          list
        }
        placa {
          title
          list
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
